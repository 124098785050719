/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';
import { } from 'jquery-match-height';
import Headroom from 'headroom.js';

$(async function () {

	// Slideshow
	$('.swiper-container').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');

		var mySwiper = new Swiper.default(this, {
			grabCursor: true,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	});

	// Set same height
	$('.has-same-height').matchHeight({
		property: 'height'
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	$('.navbar-background').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler'),
			responsiveNav = $('.navbar.responsive'),
			background = $('.navbar-background');

		responsiveNav.toggleClass('is-open');
		toggler.toggleClass('is-active');
		background.toggleClass('is-active');
		$('html').toggleClass('has-fixd-position');
	}

	// Language
	$(".responsive .navbar-item.has-dropdown.is-language").each(function () {
		var element = $(this),
			a = element.find('.navbar-link.is-arrowless');

		a.on('click', function (e) {
			e.preventDefault();
			element.toggleClass("is-open");
		});
	});

	// Lightbox	
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Jump to submitted form
	$('.form-block:has(form)').each(function () {
		var $this = $(this),
			$form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name');

		$form.attr('action', $form.attr('action') + '#' + anchor);
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Headeroom
	$('body').each(function () {
		var headroom = new Headroom(this);
		headroom.init();
	});
});